import { config } from "@app/configs";

export const timestamp = (time?: number | string): number => {
  const date = time ? new Date(time) : new Date();
  return Math.floor(date.getTime() / 1000);
};

export const intervalDurationTimer = (): number => {
  if (+config?.accountLiveRefreshTimer <= 0) {
    return -1;
  }

  return +config.accountLiveRefreshTimer * 1000;
};
