import axios, { AxiosResponse } from "axios";

import { store } from "@app/store";
import { authActions } from "@app/store/auth";
import { apiUrl } from "@app/utils";

const axiosClient = axios.create({
  baseURL: apiUrl('api'),
  headers: {
    "Content-Type": "application/json",
  },
});

axiosClient.interceptors.request.use(async (config: any) => {
  const headers: {
    Authorization?: string;
    Language?: string;
  } = {};

  const accessToken = store.getState().auth?.accessToken || "";
  if (accessToken?.length) {
    headers.Authorization = `Bearer ${accessToken}`;
  }
  headers.Language = "en";

  return { ...config, headers: { ...config.headers, ...headers } };
});

axiosClient.interceptors.response.use(
  (response: AxiosResponse) => {
    const { data } = response;
    return { ...data, error: data?.error || "", errors: data?.errors || {} };
  },
  (error) => {
    if ([401, 423, 511].includes(error?.response?.status || 0)) {
      store.dispatch(authActions.logout());
    }
    return Promise.resolve(error?.response?.data);
  }
);

export default axiosClient;
