import {
  IApiResponse,
  IPaginationData,
  IPaginationFilters,
  IUserLicense,
} from "@app/types";
import axiosClient from "./axiosClient";

const prefix = '/user-licenses';
export const userLicenseApi = {
  fetchPaginate: (params?: IPaginationFilters): Promise<IApiResponse<{ paginate?: IPaginationData<IUserLicense> }>> => {
    const url = prefix;
    return axiosClient.get(url, { params });
  },
};
