import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LazyLoad } from "@app/utils";

const RouteComponent = () => {
  const ProductsRoutes = LazyLoad(() => import("./ProductsRoutes"));
  const UserRoutes = LazyLoad(() => import("./UserRoutes"));

  const Layout = LazyLoad(() => import("@app/layouts"));
  const RegisteredRoute = LazyLoad(() => import("./RegisteredRoute"));
  const UnregisterRoute = LazyLoad(() => import("./UnregisterRoute"));

  const RefreshToken = LazyLoad(() => import("./RefreshToken"));
  const LoginPage = LazyLoad(() => import("@app/features/auth/login"));
  const LogoutPage = LazyLoad(() => import("@app/features/auth/logout"));
  const RegisterPage = LazyLoad(() => import("@app/features/auth/register"));

  const ContactUsPage = LazyLoad(
    () => import("@app/features/contact/ContactUsPage")
  );
  const PaymentSuccessPage = LazyLoad(
    () => import("@app/features/payments/PaymentSuccessPage")
  );

  const HomePage = LazyLoad(() => import("@app/features/home/HomePage"));
  const PageView = LazyLoad(() => import("@app/features/pages/PageView"));
  const ForgotPasswordPage = LazyLoad(
    () => import("@app/features/auth/forgot-password")
  );
  const ResetPasswordPage = LazyLoad(
    () => import("@app/features/auth/reset-password")
  );
  const FAQsPage = LazyLoad(() => import("@app/features/faqs/FAQsPage"));

  return (
    <BrowserRouter>
      <RefreshToken />
      <Routes>
        <Route element={<Layout />}>
          <Route element={<RegisteredRoute />}>
            <Route path="logout" element={<LogoutPage />} />
            <Route path="user/*" element={<UserRoutes />} />
            {/* <Route path="payments/*" element={<PaymentRoutes />} /> */}
            <Route
              path="purchases/license/purchased"
              element={<PaymentSuccessPage />}
            />
          </Route>
          <Route element={<UnregisterRoute />}>
            <Route path="login" element={<LoginPage />} />
            <Route path="register" element={<RegisterPage />} />
            <Route path="forgot-password" element={<ForgotPasswordPage />} />
            <Route
              path="reset-password/:token"
              element={<ResetPasswordPage />}
            />
          </Route>
          <Route path="products/*" element={<ProductsRoutes />} />
          <Route path="contacts-us/*" element={<ContactUsPage />} />
          <Route path="introduce" element={<PageView slug="introduce" />} />
          <Route
            path="auto-trading"
            element={<PageView slug="auto-trading" />}
          />
          <Route path="affiliate" element={<PageView slug="affiliate" />} />
          <Route path="faqs" element={<FAQsPage />} />
          <Route index path="*" element={<HomePage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
export default RouteComponent;
