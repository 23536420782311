import axiosClient from "./axiosClient";
import { IApiResponse, IForgotPasswordFormData, ILoginData, IRegisterData, IResetPasswordFormData, IUser } from "@app/types";

export const authApi = {
  fetchRefreshToken: (): Promise<IApiResponse<{ accessToken?: string }>> => {
    const url = `/auth/refresh-token`;
    return axiosClient.post(url);
  },

  fetchVisitor: (): Promise<
    IApiResponse<{
      visitor?: IUser;
    }>
  > => {
    return axiosClient.get("/users/me");
  },

  sendLogin: (
    params: ILoginData
  ): Promise<IApiResponse<{ accessToken?: string }>> => {
    const url = `/auth/login`;
    return axiosClient.post(url, params);
  },

  sendRegister: (
    params: IRegisterData
  ): Promise<IApiResponse<{ accessToken?: string }>> => {
    const url = `/auth/register`;
    return axiosClient.post(url, params);
  },

  sendForgotPassword: (
    params: IForgotPasswordFormData
  ): Promise<IApiResponse<{ successfully?: boolean, message?: string }>> => {
    const url = `/auth/forgot-password`;
    return axiosClient.post(url, params);
  },

  sendResetPassword: (
    params: IResetPasswordFormData
  ): Promise<IApiResponse<{ successfully?: boolean, message?: string }>> => {
    const url = `/auth/reset-password`;
    return axiosClient.post(url, params);
  },
};
