import { RootState } from "@app/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILanguageState } from "./types";
import { SLanguages } from "./languages";

const initialState: ILanguageState = SLanguages[0];

const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    change: (state, action: PayloadAction<ILanguageState>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const languageActions = languageSlice.actions;
export const languageReducer = languageSlice.reducer;

export const selectLanguage = (state: RootState) =>
  state.language || SLanguages[0];
export const selectLanguageCode = (state: RootState) =>
  state.language?.code || "";
