import { IApiResponse, IMessage } from "@app/types";
import axiosClient from "./axiosClient";

export const messageApi = {
  sendMessage: (
    params: IMessage
  ): Promise<IApiResponse<{ successfully: boolean }>> => {
    const url = `/contacts/send-message`;
    return axiosClient.post(url, params);
  },
};
