import { IPurchaseLicenseFormData } from "./../types/license";
import {
  IApiResponse,
  ILicense,
  IPaginationData,
  IPaymentGateway,
  IProductParams,
  IProducts,
  IProductsPaginatorParams,
  IRealtime,
  IRealtimeFilter,
  IUserLicense,
} from "@app/types";
import { IFAQ } from "@app/types/FQA";
import axiosClient from "./axiosClient";

export const productApi = {
  getProductsList: (
    limit: number,
    params?: IProductParams
  ): Promise<IApiResponse<{ products?: IProducts[] }>> => {
    const url = `/products/sorted`;
    return axiosClient.get(url, { params: { ...params, limit } });
  },

  getProductsPaginate: (
    params?: IProductsPaginatorParams
  ): Promise<IApiResponse<{ paginate?: IPaginationData<IProducts> }>> => {
    const url = `/products/sorted`;
    return axiosClient.get(url, { params });
  },

  getProductView: (
    slug: string
  ): Promise<IApiResponse<{ product?: IProducts }>> => {
    const url = `/products/${slug}/view`;
    return axiosClient.get(url);
  },

  getProductRealtime: (
    slug: string,
    params: IRealtimeFilter
  ): Promise<IApiResponse<{ paginate?: IPaginationData<IRealtime> }>> => {
    const url = `/products/${slug}/transactions`;
    return axiosClient.get(url, { params });
  },

  getProductFAQ: (slug: string): Promise<IApiResponse<{ faqs?: IFAQ[] }>> => {
    const url = `/products/${slug}/faqs`;
    return axiosClient.get(url);
  },

  getLicenses: (
    slug: string
  ): Promise<IApiResponse<{ licenses?: ILicense[] }>> => {
    const url = `/products/${slug}/licenses`;
    return axiosClient.get(url);
  },

  getPaymentGateways: (
    slug: string
  ): Promise<IApiResponse<{ paymentGateways?: IPaymentGateway[] }>> => {
    const url = `/products/${slug}/payment-gateways`;
    return axiosClient.get(url);
  },

  sendPurchaseLicense: (
    slug: string,
    params: IPurchaseLicenseFormData
  ): Promise<IApiResponse<{ redirect?: string }>> => {
    const url = `/products/${slug}/purchase`;
    return axiosClient.post(url, params);
  },

  sendTrialLicense: (
    slug: string
  ): Promise<IApiResponse<{ userLicense?: IUserLicense }>> => {
    const url = `/products/${slug}/trial-license`;
    return axiosClient.post(url);
  },
};
