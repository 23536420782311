import {
  IApiResponse,
  IChangeEmailData,
  IChangePasswordData,
  IUser,
} from "@app/types";
import axiosClient from "./axiosClient";

export const userApi = {
  changeUserName: (
    name: string
  ): Promise<IApiResponse<{ successfully?: boolean; user: IUser }>> => {
    const url = `/users/change-name`;
    return axiosClient.post(url, { name });
  },

  changeUserEmail: (
    params: IChangeEmailData
  ): Promise<IApiResponse<{ successfully?: boolean; user: IUser }>> => {
    const url = `/users/change-email`;
    return axiosClient.post(url, params);
  },

  changeUserPassword: (
    params: IChangePasswordData
  ): Promise<IApiResponse<{ successfully?: boolean; user: IUser }>> => {
    const url = `/users/change-password`;
    return axiosClient.post(url, params);
  },
};
