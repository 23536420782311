let config: {
  siteName: string;
  apiUrl: string;
  accountLiveRefreshTimer: number;
} = {
  siteName: "",
  apiUrl: "",
  accountLiveRefreshTimer: 0,
};

if (process.env?.["NODE_ENV"] === "development") {
  config = {
    siteName: process.env?.["REACT_APP_SITE_NAME"] || "",
    apiUrl: process.env?.["REACT_APP_API_URL"] || "",
    accountLiveRefreshTimer: +(process.env?.["REACT_APP_ACCOUNT_LIVE_REFRESH_TIMER"] || 0),
  };
} else if (typeof window?._env !== "undefined") {
  config = {
    siteName: window?._env?.REACT_APP_SITE_NAME || "",
    apiUrl: window?._env?.REACT_APP_API_URL || "",
    accountLiveRefreshTimer: +(window?._env?.REACT_APP_ACCOUNT_LIVE_REFRESH_TIMER || 0)
  };
}

export default config;
