export const protectLicenseKey = (key: string) => {
  if (!key) {
    return '';
  }
  const chars = key.split('').map((char, index) => {
    if (index <= 2 || index + 3 >= key.length) {
      return char;
    }
    return '*';
  });

  return chars.join('').replace(/\*{1,}/g, '***');
};
