import { lazy, Suspense, ComponentType } from "react";

export const LazyLoad = (
  importFunc: () => Promise<{ default: ComponentType<any> }>
) => {
  const LazyComponent = lazy(importFunc);
  return ({ ...props }) => (
    <Suspense fallback={<></>}>
      <LazyComponent {...props} />
    </Suspense>
  );
};
