import { Suspense } from "react";
import * as ReactDOM from "react-dom/client";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";

import { persistor, store } from "./store";
import { languages } from "@app/configs";
import Routes from "./routes";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <StrictMode>
  <Provider store={store}>
    <I18nextProvider i18n={languages}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback="">
          <Routes />
        </Suspense>
      </PersistGate>
    </I18nextProvider>
  </Provider>
  // </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
