import { combineReducers } from "redux";
import { headerReducer } from "./header";
import { authReducer } from "./auth";
import { languageReducer } from "./language";

const rootReducer = combineReducers({
  header: headerReducer,
  auth: authReducer,
  language: languageReducer,
});

export default rootReducer;
