import { apiUrl } from "./apiUrl";

export const imgLinktoServer = apiUrl('/storage/')

export enum OrderType {
  BUY = 0,
  SELL = 1,
  BUYLIMIT = 2,
  SELLLIMIT = 3,
  BUYSTOP = 4,
  SELLSTOP = 5,
}

export function getOrderType(order: OrderType): string {
  switch (order) {
    case OrderType.BUY:
      return "BUY";
    case OrderType.SELL:
      return "SELL";
    case OrderType.BUYLIMIT:
      return "BUYLIMIT";
    case OrderType.SELLLIMIT:
      return "SELLLIMIT";
    case OrderType.BUYSTOP:
      return "BUYSTOP";
    case OrderType.SELLSTOP:
      return "SELLSTOP";
  }
}
